import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"


const FourOhFour = () => (
  <Layout>
    <Seo 
      title="Page Not Found"
      desc="This is not the page you’re looking for."
      ogtitle="Page Not Found | PMR and You Website"
      ogdesc="This is not the page you’re looking for."
    />

    <div className="main-hero four-oh-four">
      <h1 className="headline-hero">404 Error</h1>
    </div>
    <div className="content-container">
      <h2 className="mb-2 md_mb-2"><strong>Don’t worry, we’ll be back on track in no time!</strong></h2>
      <p className="mb-4 md_mb-6">This page does not exist or another error has occurred. Visit our <Link to="/" className="color-dark-red">home page</Link> or go back to the <Link to="/" className="color-dark-red">previous page</Link>.</p>
    </div>
    <br/>
  </Layout>
)

export default FourOhFour
